// Libraries
import cx from 'classnames';
import { Close as Cancel, UploadFile, CheckCircle } from '@mui/icons-material';
import ErrorIcon from '@mui/icons-material/Error';

import {
  Stack,
  Avatar,
  Grid,
  LinearProgress,
  Typography,
  IconButton,
} from '@mui/material';

// Files
import { FileUploadProgressProps } from './interface';
import { formatBytes } from '../../utils/functions/bytes';

// scss
import './FileUploadProgress.scss';

const FileUploadProgress = ({
  loading,
  error,
  file,
  progress,
  onReset,
}: FileUploadProgressProps) => {
  if (!file || !progress) return null;

  const isCompleted = progress === 100 && !loading;

  return (
    <Grid
      container
      className={cx('file-upload-progress', {
        'is-completed': isCompleted,
      })}
    >
      <Avatar className='file-upload-progress-avatar'>
        <UploadFile className='file-upload-progress-icon' />
      </Avatar>

      <Grid item className='file-upload-progress-status'>
        <Typography variant='subtitle1'>{file.name}</Typography>
        <Typography
          variant='body2'
          className='file-upload-progress-status-text'
        >
          {formatBytes(file.size)} &bull;{' '}
          {isCompleted ? 'Completed' : 'Loading'}
        </Typography>

        {!isCompleted && (
          <LinearProgress variant='determinate' value={progress} />
        )}
      </Grid>

      <Grid item>
        <Stack
          className='file-upload-progress__actions'
          direction='row'
          spacing={1}
        >
          <IconButton aria-label='Remove company Logo' onClick={onReset}>
            <Cancel className='file-upload-progress-cancel' />
          </IconButton>

          {isCompleted &&
            (error ? (
              <ErrorIcon
                className='file-upload-progress-error'
                fontSize='inherit'
              />
            ) : (
              <CheckCircle
                className='file-upload-progress-success'
                fontSize='inherit'
              />
            ))}
        </Stack>
      </Grid>
    </Grid>
  );
};

export default FileUploadProgress;
