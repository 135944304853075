import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { useController, useFormContext } from 'react-hook-form';
import { DropdownProps, DropdownItemProps } from './interface';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const renderMenuItems = (items: DropdownItemProps[] | string[]) => {
  return items.map((item, i) =>
    typeof item === 'object' ? (
      <MenuItem key={i} value={item.value.toLowerCase()}>
        {item.label}
      </MenuItem>
    ) : (
      <MenuItem key={i} value={item.toLowerCase()}>
        {item}
      </MenuItem>
    ),
  );
};

const Dropdown = ({ items, label, name, ...rest }: DropdownProps) => {
  const { control } = useFormContext();
  const {
    field: { onChange, value },
  } = useController({
    control,
    name,
    defaultValue: '',
  });

  return (
    <FormControl {...rest}>
      <InputLabel>{label}</InputLabel>
      <Select label={label} value={value} onChange={onChange}>
        {renderMenuItems(items)}
      </Select>
    </FormControl>
  );
};

export default Dropdown;
