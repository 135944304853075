// Libraries
import { useState, Fragment } from 'react';
import { Button, Dialog, DialogContent, DialogTitle } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

// Files
import TechniciansAddForm from '../TechniciansAddForm/TechniciansAddForm';

// Scss
import './TechniciansAddModal.scss';

const TechniciansAddModal = () => {
  const [open, setOpen] = useState<boolean>(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <Fragment>
      <Button
        startIcon={<AddIcon />}
        variant='contained'
        color='primary'
        onClick={handleOpen}
      >
        Add Technician
      </Button>

      <Dialog
        open={open}
        className='technicians-add-modal'
        onClose={handleClose}
      >
        {handleClose ? (
          <IconButton
            className='technicians-add-modal__close-btn'
            size='small'
            aria-label='close'
            onClick={handleClose}
          >
            <CloseIcon fontSize='inherit' />
          </IconButton>
        ) : null}
        <DialogTitle align='center'>Add Technician</DialogTitle>

        <DialogContent>
          <TechniciansAddForm onClose={handleClose} />
        </DialogContent>
      </Dialog>
    </Fragment>
  );
};

export default TechniciansAddModal;
