// Components
import { AxiosError } from 'axios';

// Files
import { axiosApi } from '../../config/rtk/axiosApi/axiosApi';

import {
  GetAccountTechniciansResponse,
  GetAccountCompanyServiceResponse,
  GetAccountInfoResponse,
  UpdateAccountFormData,
  UpdateAccountInfoResponse,
  GetAccountInfoAndServicesResponse,
  UpdateAccountTechniciansResponse,
  UpdateAccountTechniciansFormData,
  uploadAccountLogoResponse,
  uploadAccountLogoFormData,
  updateAccountLogoFormData,
  updateAccountLogoResponse,
  AccountCompleteWelcomeResponse,
} from './interface';

const accountApi = axiosApi.injectEndpoints({
  endpoints: (build) => ({
    getAccountInfoAndServices: build.query<
      GetAccountInfoAndServicesResponse,
      void
    >({
      async queryFn(_arg, _queryApi, _extraOptions, baseQuery) {
        const result = {
          data: {},
        } as GetAccountInfoAndServicesResponse;
        const accountInfo = await baseQuery({
          url: '/company',
          method: 'GET',
        });

        if (accountInfo.error)
          return { error: accountInfo.error as AxiosError };
        result.data.account = accountInfo.data as GetAccountInfoResponse;

        const accountServices = await baseQuery({
          url: '/services',
          method: 'GET',
        });

        if (accountServices.error)
          return { error: accountServices.error as AxiosError };
        result.data.services =
          accountServices.data as GetAccountCompanyServiceResponse;

        return { data: result };
      },
      providesTags: () => [
        { type: 'Account', id: 'ACCOUNT-DETAILS' },
        { type: 'Account', id: 'ACCOUNT-COMPANY-SERVICES' },
      ],
    }),
    getAccountInfo: build.query<GetAccountInfoResponse, void>({
      query: () => ({
        url: '/company',
        method: 'GET',
      }),
      providesTags: () => [{ type: 'Account', id: 'ACCOUNT-DETAILS' }],
    }),
    getAccountCompanyServices: build.query<
      GetAccountCompanyServiceResponse,
      null
    >({
      query: () => ({
        url: '/services',
        method: 'GET',
      }),
      providesTags: () => [{ type: 'Account', id: 'ACCOUNT-COMPANY-SERVICES' }],
    }),
    getAccountTechnicians: build.query<GetAccountTechniciansResponse, void>({
      query: () => ({
        url: '/company/technicians',
        method: 'GET',
      }),
    }),
    updateAccountTechnicians: build.mutation<
      UpdateAccountTechniciansResponse,
      UpdateAccountTechniciansFormData
    >({
      query: ({ data }) => ({
        url: '/company/technicians',
        method: 'POST',
        data,
      }),
    }),
    updateAccountInfo: build.mutation<
      UpdateAccountInfoResponse,
      UpdateAccountFormData
    >({
      query: ({ data, onUploadProgress }: UpdateAccountFormData) => {
        return {
          url: '/company',
          method: 'PATCH',
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          onUploadProgress,
          data,
        };
      },
      invalidatesTags: () => [{ type: 'Account', id: 'ACCOUNT-DETAILS' }],
    }),
    uploadAccountLogo: build.mutation<
      uploadAccountLogoResponse,
      uploadAccountLogoFormData
    >({
      query: ({ data, onUploadProgress }: UpdateAccountFormData) => {
        return {
          url: '/company/logo-upload',
          method: 'POST',
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          onUploadProgress,
          data,
        };
      },
    }),
    updateAccountLogo: build.mutation<
      updateAccountLogoResponse,
      updateAccountLogoFormData
    >({
      query: ({ data }: updateAccountLogoFormData) => {
        return {
          url: '/company/logo',
          method: 'POST',
          data,
        };
      },
      invalidatesTags: () => [{ type: 'Account', id: 'ACCOUNT-DETAILS' }],
    }),
    accountCompleteWelcome: build.mutation<
      AccountCompleteWelcomeResponse,
      void
    >({
      query: () => {
        return {
          url: '/company/complete-welcome',
          method: 'POST',
        };
      },
      invalidatesTags: () => [{ type: 'Account', id: 'ACCOUNT-DETAILS' }],
    }),
  }),
});

export const {
  useUploadAccountLogoMutation,
  useUpdateAccountTechniciansMutation,
  useGetAccountTechniciansQuery,
  useGetAccountInfoAndServicesQuery,
  useUpdateAccountInfoMutation,
  useLazyGetAccountInfoQuery,
  useGetAccountInfoQuery,
  useGetAccountCompanyServicesQuery,
  useUpdateAccountLogoMutation,
  useAccountCompleteWelcomeMutation,
} = accountApi;
