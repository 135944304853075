// Components
import StateHandlerLoading from '../../../../common/components/StateHandler/components/StateHandlerLoading/StateHandlerLoading';
import StateHandlerError from '../../../../common/components/StateHandler/components/StateHandlerError/StateHandlerError';
import ConfirmTechForm from '../../../../common/components/ConfirmTechForm/ConfirmTechForm';

// Files
import { useGetAccountTechniciansQuery } from '../../../../features/account/accountSlice';

const OnboardingStepFour = () => {
  const { data, isLoading } = useGetAccountTechniciansQuery();

  if (isLoading) return <StateHandlerLoading />;
  if (!data) return <StateHandlerError />;

  return <ConfirmTechForm data={data.data} />;
};

export default OnboardingStepFour;
