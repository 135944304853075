/**
 * External Dependencies
 */
import { useState, Fragment } from 'react';
import { Outlet } from 'react-router-dom';
import { Box, Pagination } from '@mui/material';

// Components
import TechniciansTopBar from './Partials/TechniciansTopBar';
import TechniciansList from './../../common/components/TechniciansList/TechniciansList';
import TechnicianInvitationSidebar from '../../common/components/TechnicianInvitationSidebar/TechnicianInvitationSidebar';
import StateHandler from '../../common/components/StateHandler/StateHandler';
import StateHandlerEmpty from '../../common/components/StateHandlerEmpty/StateHandlerEmpty';

// Files
import { useGetTechnicianListQuery } from '../../features/technician/technicianSlice';
import TechniciansNotFound from '../../assets/images/technicians-not-found.svg';

// Scss
import './Technicians.scss';

const Technicians = () => {
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState('');
  const { data, isLoading, isError, isSuccess } = useGetTechnicianListQuery({
    page,
    search_string: search,
  });

  const handleSearch = (value: string): void => {
    setPage(1);
    setSearch(value);
  };

  return (
    <Box className='technicians'>
      <Box className='technicians__list'>
        <TechniciansTopBar onSearch={handleSearch} />

        <StateHandler
          isLoading={isLoading}
          isError={isError}
          isEmpty={isSuccess && data?.data.length === 0}
          emptyComponent={
            <StateHandlerEmpty
              image={TechniciansNotFound}
              title={
                search.length
                  ? 'No technicians match your criteria.'
                  : 'You haven’t added any technicians.'
              }
            />
          }
        >
          {data?.data ? (
            <Fragment>
              <TechniciansList data={data.data} />

              {data?.meta.last_page > 1 && (
                <Pagination
                  page={+page}
                  count={data.meta.last_page}
                  variant='outlined'
                  shape='rounded'
                  onChange={(_, page: number) => setPage(page)}
                />
              )}
            </Fragment>
          ) : null}
        </StateHandler>
      </Box>

      <TechnicianInvitationSidebar />

      <Outlet />
    </Box>
  );
};

export default Technicians;
