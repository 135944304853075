// Libraries
import { useEffect } from 'react';
import {
  useLazyListNotificationsQuery,
  useMarkNotificationAsReadMutation,
} from '../../../features/notifications/toasts/toastSlice';
import { StyledAlertProps } from '../../components/StyledAlert/interface';
import { useAppSelector } from '../../../config/redux/hooks';
import useExtendedSnackbar from '../useExtendedSnackbar/useExtendedSnackbar';

const useServerSnackbar = (): void => {
  const [showToast, closeToast] = useExtendedSnackbar();
  const token = useAppSelector((state) => state.persist.token);
  const [getNotifications] = useLazyListNotificationsQuery();
  const [markNotificationAsRead] = useMarkNotificationAsReadMutation();

  const handleMarkNotificationRead = (serverId: string, toastId: string) => {
    markNotificationAsRead(serverId)
      .unwrap()
      .then(() => {
        closeToast(toastId);
      })
      .catch(() => {
        // TODO Think of better way to handle any potential errors
        console.log('Something went wrong...');
      });
  };

  const handleGetNotifications = () => {
    getNotifications('', false)
      .unwrap()
      .then((results) => {
        results.map((result) => {
          const toastId = result.id;
          const toastProps = {
            ...result.data,
            onActionClick(id: string) {
              handleMarkNotificationRead(toastId, id);
            },
            persist: true,
          } as StyledAlertProps;

          showToast(toastProps);
        });
      });
  };

  useEffect(() => {
    if (!token) return;
    handleGetNotifications();

    return () => {
      closeToast();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);
};

export default useServerSnackbar;
