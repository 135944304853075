// Libraries
import { Fragment, useState } from 'react';
import { FieldValues } from 'react-hook-form';
import { Stack, Pagination } from '@mui/material';
import { useSearchParams } from 'react-router-dom';

// Components
import StoresTopBar from './Partials/StoresTopBar';
import StoresCards from './Partials/StoresCards';
import StoreCardFilters from '../../common/components/StoreCardFilters/StoreCardFilters';
import StateHandler from '../../common/components/StateHandler/StateHandler';
import StateHandlerEmpty from '../../common/components/StateHandlerEmpty/StateHandlerEmpty';

// Files
import StoresNotFound from '../../assets/images/stores-not-found.svg';
import useNavigateSearch from '../../common/hooks/useNavigateSearch/useNavigateSearch';

// Scss
import './stores.scss';

// Features
import { useGetAccountSupplierListQuery } from '../../features/accountSupplier/accountSupplierSlice';

const Stores = () => {
  const [searchParams] = useSearchParams();
  const [page, setPage] = useState(searchParams.get('page') || '1');
  const [filters, setFilters] = useState<FieldValues>({
    filter: searchParams.get('filter') || '',
  });
  const hasFiltersApplied = Object.keys(filters).length !== 0;
  const queryParams = {
    page,
    ...filters,
  };
  const {
    isLoading,
    isError,
    data: stores,
  } = useGetAccountSupplierListQuery(queryParams);
  const navigateSearch = useNavigateSearch({
    replace: true,
  });

  const handleOnFiltersChange = (data: FieldValues) => {
    const newQueryParams = { ...data, page: '1' };
    setPage(newQueryParams.page);
    setFilters({ ...data });
    navigateSearch('/stores', newQueryParams);
  };

  const handleOnPageChange = (page: string) => {
    setPage(page);
    window?.scrollTo(0, 0);
    navigateSearch('/stores', { ...queryParams, page });
  };

  return (
    <Stack className='stores' spacing={3}>
      <StoresTopBar />

      <StoreCardFilters onChange={handleOnFiltersChange} />

      <StateHandler
        isLoading={isLoading}
        isError={isError}
        isEmpty={!stores?.data.length}
        emptyComponent={
          <StateHandlerEmpty
            title={
              hasFiltersApplied
                ? 'No stores match your criteria'
                : 'You haven’t added any Stores.'
            }
            image={StoresNotFound}
          />
        }
      >
        {stores && (
          <Fragment>
            <StoresCards data={stores?.data} />

            {stores?.meta.last_page > 1 && (
              <Pagination
                page={+page}
                count={stores.meta.last_page}
                variant='outlined'
                shape='rounded'
                onChange={(_, val: number) =>
                  handleOnPageChange(val.toString())
                }
              />
            )}
          </Fragment>
        )}
      </StateHandler>
    </Stack>
  );
};

export default Stores;
