/**
 * External Dependencies
 */

import { Box, Grid, Typography } from '@mui/material';

/**
 * Internal Dependencies
 */
import SearchInput from '../../../common/components/SearchInput/SearchInput';
import TechniciansAddModal from './../../../common/components/TechniciansAddModal/TechniciansAddModal';

export interface TechniciansTopBarProps {
  onSearch: (query: string) => void;
}

const TechniciansTopBar = ({ onSearch = () => {} }: TechniciansTopBarProps) => {
  return (
    <Grid className='technicians__top-bar' container>
      <Grid item>
        <Typography variant='h4'>Your Techs on Bluon</Typography>
      </Grid>

      <Grid item flexGrow={1}>
        <Box className='technicians__search'>
          <SearchInput placeholder='Search Techs by Name' onSearch={onSearch} />
        </Box>
      </Grid>

      <Grid item>
        <TechniciansAddModal />
      </Grid>
    </Grid>
  );
};

export default TechniciansTopBar;
