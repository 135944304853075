import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { LogoState, LogoUrlState } from './interface';

import companyApi from '../company/companySlice';

const initialState: LogoState = {
  url: '',
};

const logoSlice = createSlice({
  name: 'logo',
  initialState,
  reducers: {
    setLogo: (state, { payload: { url } }: PayloadAction<LogoUrlState>) => {
      state.url = url;
    },
  },
  extraReducers(builder) {
    builder.addMatcher(
      companyApi.endpoints.updateCompany.matchFulfilled,
      (state, { payload }) => {
        if (state.url !== payload.data.logo.url) {
          state.url = payload.data.logo.url;
        }
      },
    );
  },
});

export const { setLogo } = logoSlice.actions;

export default logoSlice.reducer;
