// Files
import { AxiosError } from 'axios';
import { axiosApi } from '../../config/rtk/axiosApi/axiosApi';
import {
  Company,
  UpdateCompanyResponse,
  UpdateCompanyFormData,
  getCompanyAndServicesResponse,
} from './interface';

const companyApi = axiosApi.injectEndpoints({
  endpoints: (build) => ({
    getCompanyAndServices: build.query<getCompanyAndServicesResponse, void>({
      async queryFn(_arg, _queryApi, _extraOptions, baseQuery) {
        const data = {} as getCompanyAndServicesResponse;

        const companyResult = await baseQuery({
          url: '/company',
          method: 'GET',
        });

        if (companyResult.error) {
          return { error: companyResult.error as AxiosError };
        }
        data.company =
          companyResult.data as getCompanyAndServicesResponse['company'];

        const servicesResult = await baseQuery({
          url: '/services',
          method: 'GET',
        });

        if (companyResult.error) {
          return { error: servicesResult.error as AxiosError };
        }
        data.services =
          servicesResult.data as getCompanyAndServicesResponse['services'];

        return { data };
      },
      providesTags: () => [{ type: 'Company', id: 'DETAILS' }],
    }),
    getCompany: build.query<Company, void>({
      query: () => ({
        url: '/company',
        method: 'GET',
      }),
      providesTags: () => [{ type: 'Company', id: 'DETAILS' }],
      transformResponse: (response: { data: Company }) => response.data,
    }),
    updateCompany: build.mutation<UpdateCompanyResponse, UpdateCompanyFormData>(
      {
        query: (data) => ({
          url: '/company',
          method: 'POST',
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          data: {
            ...data,
            // This is needed, due to backend inability to process formData
            // if the query  method is not 'POST'
            _method: 'PATCH',
          },
        }),
        invalidatesTags: [{ type: 'Company', id: 'DETAILS' }],
      },
    ),
  }),
});

export default companyApi;

export const {
  useGetCompanyAndServicesQuery,
  useGetCompanyQuery,
  useUpdateCompanyMutation,
} = companyApi;
