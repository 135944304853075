// Libraries
import { useEffect, useMemo } from 'react';
import { Grid, Typography } from '@mui/material';
import { FormProvider, useForm, FieldValues } from 'react-hook-form';

// Components
import SelectFieldInput from '../SelectFieldInput/SelectFieldInput';
import StoreFilterFormHeader from './components/StoreFilterFormHeader/StoreFilterFormHeader';
import StoreFilterFormButtonGroup from './components/StoreFilterFormButtonGroup/StoreFilterFormButtonGroup';
import TextFieldInput from '../TextFieldInput/TextFieldInput';

// Files
import { StoreFilterFormProps } from './interface';
import { RADIUS_OPTIONS, STORE_TYPE_OPTIONS } from '../../constants/constants';

// Scss
import './StoreFilterForm.scss';

const SEARCH_FIELD_LABELS = {
  default: 'Search by',
  name: 'Search by Store Name',
  address: 'Search by Address',
};

const StoreFilterForm = ({
  defaultValues,
  onChange = () => {},
}: StoreFilterFormProps) => {
  const methods = useForm({
    defaultValues,
  });
  const { watch, resetField } = methods;
  const watchType = watch('type');

  // Reset search input value when search term is changed
  useEffect(() => {
    resetField('search_string');

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchType]);

  const SearchFieldLabel = useMemo(() => {
    if (typeof watchType !== 'string') return SEARCH_FIELD_LABELS.default;

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return SEARCH_FIELD_LABELS?.[watchType] || SEARCH_FIELD_LABELS.default;
  }, [watchType]);

  useEffect(() => {
    const watchAll = watch((data: FieldValues) => {
      const { search_string } = data;
      if (search_string.length > 0 && search_string.length <= 3) return;
      onChange(data);
    });

    return () => watchAll.unsubscribe();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch]);

  return (
    <FormProvider {...methods}>
      <form className='store-add-form'>
        <StoreFilterFormHeader title='Add Store' />

        <Grid className='store-add-form__grid' container spacing={2}>
          <Grid item>
            <Grid container spacing={1}>
              <Grid item>
                <Typography variant='caption'>Search by</Typography>
              </Grid>

              <Grid item>
                <StoreFilterFormButtonGroup
                  name='type'
                  options={STORE_TYPE_OPTIONS}
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid item>
            <SelectFieldInput
              fullwidth
              size='small'
              name='radius'
              label='Radius'
              options={RADIUS_OPTIONS}
            />
          </Grid>

          <Grid item xs={12}>
            <TextFieldInput
              fullWidth
              variant='outlined'
              label={SearchFieldLabel}
              name='search_string'
            />
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );
};

export default StoreFilterForm;
