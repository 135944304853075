// Libraries
import { Box, Typography, Stack } from '@mui/material';

// Components
import Video from '../../../../common/components/Video/Video';
import OnboardingHighlightHeader from '../OnboardingHighlightHeader/OnboardingHighlightHeader';

// Files
import { OnboardingVideoHighlightProps } from './interface';

// Scss
import './OnboardingVideoHighlight.scss';

const OnboardingVideoHighlight = ({
  src,
  title,
  subTitle,
  caption,
}: OnboardingVideoHighlightProps) => {
  return (
    <Box className='onboarding-video-highlight'>
      <Stack spacing={4}>
        <OnboardingHighlightHeader title={title} subTitle={subTitle} />

        <Box>
          <Video src={src} />
        </Box>

        {caption && (
          <Box>
            <Typography variant='overline'>{caption}</Typography>
          </Box>
        )}
      </Stack>
    </Box>
  );
};

export default OnboardingVideoHighlight;
