// Libraries
import Chip from '@mui/material/Chip';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';

// Files
import { StoreSearchCardProps } from './interface';

// Scss
import './StoreSearchCard.scss';

export default function StoreSearchCard({
  data,
  selected = false,
  onClick = () => {},
}: StoreSearchCardProps) {
  const { name, address, verified, image, logo, distance } = data;

  return (
    <Card
      className={`store-search-card${selected ? ' is-selected' : ''}`}
      elevation={0}
      onClick={() => onClick(data)}
    >
      <Box className='store-search-card__media'>
        <CardMedia
          className='store-search-card__image'
          component='img'
          image={image ? image.url : 'http://via.placeholder.com/70x55'}
          alt='Live from space album cover'
        />

        <CardMedia
          className='store-search-card__logo'
          component='img'
          image={logo ? logo.url : 'http://via.placeholder.com/70x18'}
          alt='Live from space album cover'
        />
      </Box>

      <Box className='store-search-card__body'>
        <CardContent className='store-search-card__content'>
          <Typography className='store-search-card__name' variant='h6'>
            {name}
          </Typography>

          {address && (
            <Typography
              className='store-search-card__address'
              variant='body2'
              component='address'
            >
              {address}
            </Typography>
          )}
        </CardContent>
      </Box>

      <Box className='store-search-card__meta'>
        {verified && (
          <Chip
            label='On Bluon'
            color='primary'
            size='small'
            icon={<CheckCircleIcon />}
          />
        )}

        {!!distance && (
          <Typography className='store-search-card__distance' variant='caption'>
            {distance} miles
          </Typography>
        )}
      </Box>
    </Card>
  );
}
