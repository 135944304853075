// Libraries
import { useMemo, useEffect } from 'react';
import { Grid, Typography, Link } from '@mui/material';

// Components
import Stepper from '../../common/components/Stepper/Stepper';
import OnboardingStepOne from './partials/OnboardingStepOne/OnboardingStepOne';
import OnboardingStepTwo from './partials/OnboardingStepTwo/OnboardingStepTwo';
import OnboardingStepThree from './partials/OnboardingStepThree/OnboardingStepThree';
import OnboardingStepFour from './partials/OnboardingStepFour/OnboardingStepFour';
import OnboardingVideoHighlight from './partials/OnboardingVideoHighlight/OnboardingVideoHighlight';
import OnboardingImageHighlight from './partials/OnboardingImageHighlight/OnboardingImageHighlight';

// Files
import { OnboardingStep } from './interface';
import useStep from '../../common/hooks/useStep/useStep';

// Scss
import './Onboarding.scss';

const Onboarding = () => {
  const steps = useMemo<OnboardingStep[]>(
    () => [
      {
        header: {
          title: 'Step One',
          subTitle: 'See how it works',
          text: 'New to Bluon for Contractors? Check out our tutorial to explore the possibilities!',
        },
        component: OnboardingStepOne,
        highlight: {
          type: 'video',
          props: {
            src: require('../../assets/videos/bluon-video.mp4'),
            title: 'Bluon For Contractors, explained.',
            subTitle: 'This is just the tip of the iceberg.',
            caption: (
              <>
                Questions? Call us anytime!{' '}
                <Link underline='hover' href='tel:5555555569'>
                  (555) 555 - 5569
                </Link>
              </>
            ),
          },
        },
      },
      {
        header: {
          title: 'Step Two',
          subTitle: 'Confirm your information',
          text: 'Make sure we got everything right!',
        },
        component: OnboardingStepTwo,
        highlight: {
          type: 'image',
          props: {
            src: require('../../assets/images/onboarding-highlight-img-1.png'),
            title: 'You can edit this in the account tab later on',
            subTitle:
              'Shouldn’t change too often, but when it does, it is there. ',
          },
        },
      },
      {
        header: {
          title: 'Step Three',
          subTitle: 'Upload your company logo',
          text: 'This will be used across the entire portal, and can be changed at any time',
        },
        component: OnboardingStepThree,
        highlight: {
          type: 'image',
          props: {
            src: require('../../assets/images/onboarding-highlight-img-2.png'),
            title: 'Changed your logo? No problem.',
            subTitle: 'Update your logo any time in your account settings.',
          },
        },
      },
      {
        header: {
          title: 'Step Four',
          subTitle: 'Confirm your techs',
          text: 'Here you can confirm techs who have already requested to add you. ',
        },
        component: OnboardingStepFour,
        highlight: {
          type: 'image',
          props: {
            src: require('../../assets/images/onboarding-highlight-img-3.png'),
            title: 'Manage your team with little headache.',
            subTitle: 'Add, remove, and view all of your techs from one spot.',
          },
        },
      },
    ],
    [],
  );
  const { currentStep, goToNextStep } = useStep(steps.length);

  const ActiveForm = useMemo(
    () => steps[currentStep]?.component,
    [currentStep, steps],
  );

  const ActiveHighlight = useMemo(
    () =>
      steps[currentStep]?.highlight.type === 'video'
        ? OnboardingVideoHighlight
        : OnboardingImageHighlight,
    [currentStep, steps],
  );

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [currentStep]);

  return (
    <Grid className='onboarding' container>
      <Grid item className='onboarding__sidebar'>
        <div className='onboarding__header'>
          <Typography variant='h4'>
            {steps[currentStep].header.title}
          </Typography>

          <Typography variant='h5'>
            {steps[currentStep].header.subTitle}
          </Typography>

          <Typography>{steps[currentStep].header.text}</Typography>
        </div>

        <Stepper currentStep={currentStep} totalSteps={steps.length} />

        <div className='onboarding__form'>
          <ActiveForm onNextPage={goToNextStep} />
        </div>
      </Grid>

      <Grid item className='onboarding__media'>
        <ActiveHighlight {...steps[currentStep]?.highlight.props} />
      </Grid>
    </Grid>
  );
};

export default Onboarding;
