// TODO Delete next line once missing components are returned
/* eslint-disable @typescript-eslint/no-unused-vars */

// Libraries
import { Avatar, Stack, Grid, Typography, Box, Button } from '@mui/material';

// Components
import TechSkillLevel from '../../common/components/TechSkillLevel/TechSkillLevel';
import TechnicianSupplierList from '../../common/components/TechnicianSupplierList/TechnicianSupplierList';

// Scss
import './TechnicianDetails.scss';
import { useGetTechnicianDetailsQuery } from '../../features/technician/technicianSlice';
import { useParams } from 'react-router-dom';
import {
  StateHandlerLoading,
  StateHandlerError,
} from '../../common/components/StateHandler/StateHandler';
import { formatPhoneNumber } from '../../common/utils/functions/phone/format-phone-number';

const TechnicianDetails = () => {
  const { id } = useParams();
  const { data, isLoading } = useGetTechnicianDetailsQuery(id as string);

  if (isLoading) return <StateHandlerLoading />;
  if (!data) return <StateHandlerError />;

  const {
    first_name: firstName,
    last_name: lastName,
    avatar,
    email,
    job_title: jobTitle,
    bio,
    phone,
    country,
    state,
    member_since: memberSince,
  } = data;
  const fullName = `${firstName} ${lastName}`;
  const countryName = country?.name;
  const stateName = state?.name;
  const area = [stateName, countryName].filter((i) => i).join(', ');

  return (
    <Stack className='technician-details' spacing={1}>
      <Grid container className='technician-details__header' spacing={2}>
        <Grid item className='technician-details__header-avatar'>
          <Avatar alt={`${fullName}'s avatar`} src={avatar?.url}>
            {firstName[0]}
            {lastName[0]}
          </Avatar>
        </Grid>

        <Grid item className='technician-details__header-title'>
          <Typography variant='h4'>{firstName}’s Profile</Typography>
        </Grid>
      </Grid>

      {/* <Box className='technician-details__box technician-details__box--center'>
        <Typography variant='h6'>Adjust Skill Level</Typography>

        <TechSkillLevel readOnly size='large' value={skillLevel} />
      </Box> */}

      <Box className='technician-details__box'>
        <Typography variant='h6'>Tech Information</Typography>

        <Grid
          className='technician-details__grid'
          container
          columnSpacing={1}
          rowSpacing={2}
        >
          <Grid item xs={6}>
            <Typography
              className='technician-details__grid-title'
              variant='body2'
            >
              Name:
            </Typography>

            <Typography>{fullName}</Typography>
          </Grid>

          <Grid item xs={6}>
            <Typography
              className='technician-details__grid-title'
              variant='body2'
            >
              Job Title
            </Typography>

            {jobTitle && <Typography>{jobTitle}</Typography>}
          </Grid>

          <Grid item xs={6}>
            <Typography
              className='technician-details__grid-title'
              variant='body2'
            >
              Email
            </Typography>

            <Typography>{email}</Typography>
          </Grid>

          <Grid item xs={6}>
            <Typography
              className='technician-details__grid-title'
              variant='body2'
            >
              Phone Number
            </Typography>

            {phone && <Typography>{formatPhoneNumber(phone)}</Typography>}
          </Grid>

          <Grid item xs={6}>
            <Typography
              className='technician-details__grid-title'
              variant='body2'
            >
              Bluon Member Since:
            </Typography>

            {memberSince && (
              <Typography>
                {new Date(memberSince).toLocaleDateString('en-US')}
              </Typography>
            )}
          </Grid>

          <Grid item xs={6}>
            <Typography
              className='technician-details__grid-title'
              variant='body2'
            >
              Area:
            </Typography>

            {area && <Typography>{area}</Typography>}
          </Grid>

          <Grid item xs={12}>
            <Typography
              className='technician-details__grid-title'
              variant='body2'
            >
              Bio
            </Typography>

            {!!bio && <Typography>{bio}</Typography>}
          </Grid>

          {/* <Grid item xs={12}>
            <TechnicianSupplierList />
          </Grid> */}
        </Grid>
      </Box>

      {/* <Box className='technician-details__box technician-details__box--center'>
        <Typography color='error' variant='h6'>
          Remove [Tech Name]
        </Typography>

        <Button variant='contained' color='error'>
          Unlink
        </Button>
      </Box> */}
    </Stack>
  );
};

export default TechnicianDetails;
