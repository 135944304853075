/* eslint-disable @typescript-eslint/no-explicit-any */
// Libraries
import { useState, useEffect, useCallback, useMemo } from 'react';
import { UseQuery } from '@reduxjs/toolkit/dist/query/react/buildHooks';

// Files
import { Paginated } from './../../interfaces/paginated';

const useInfiniteScrollQuery = (
  useGetDataListQuery: UseQuery<any>,
  { ...queryParameters },
) => {
  const [localPage, setLocalPage] = useState(1);
  const [combinedData, setCombinedData] = useState<any[]>([]);
  const queryResponse = useGetDataListQuery(
    {
      page: localPage,
      ...queryParameters,
    },
    {
      refetchOnMountOrArgChange: true,
    },
  );

  const {
    data: fetchData = [],
    meta: { current_page: remotePage = 1, last_page: remoteTotal = 0 } = {},
  } = (queryResponse?.data as Paginated<any>) || {};
  const hasNextPage = localPage < remoteTotal && localPage === remotePage;

  const refresh = useCallback(() => {
    setLocalPage(1);
  }, []);

  const loadMore = () => {
    if (hasNextPage) {
      setLocalPage((page) => page + 1);
    }
  };

  const isEmpty = useMemo(
    () => !fetchData.length && !hasNextPage,
    [fetchData, hasNextPage],
  );

  useEffect(() => {
    if (localPage === 1) setCombinedData(fetchData);
    else if (localPage === remotePage) {
      setCombinedData((previousData) => [...previousData, ...fetchData]);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchData]);

  return {
    combinedData,
    localPage,
    loadMore,
    refresh,
    hasNextPage,
    isEmpty,
    isLoading: queryResponse?.isLoading,
    isFetching: queryResponse?.isFetching,
  };
};

export default useInfiniteScrollQuery;
