// Libraries
import { useEffect, useState } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import { InputBase, Box } from '@mui/material';

// Files
import { SearchInputProps } from './interface';

// Hooks
import useDebounce from './../../hooks/useDebounce/useDebounce';

// Scss
import './SearchInput.scss';

const SearchInput = ({
  initialQuery = '',
  placeholder = 'Search...',
  minCharacters = 4,
  delay = 500,
  onSearch = () => {},
}: SearchInputProps) => {
  const [query, setQuery] = useState<string>(initialQuery);
  const debouncedQuery = useDebounce<string>(query, delay);

  useEffect(() => {
    if (query.length === 0 || query.length >= minCharacters) {
      onSearch(debouncedQuery);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedQuery]);

  return (
    <Box className='search-input'>
      <Box className='search-input__icon-wrapper'>
        <SearchIcon className='search-input__icon' />
      </Box>

      <InputBase
        className='search-input__input-base'
        onChange={(e) => setQuery(e.target.value)}
        placeholder={placeholder}
        inputProps={{ 'aria-label': 'search' }}
      />
    </Box>
  );
};

export default SearchInput;
