// TODO Delete next line once actions and alerts are visible
/* eslint-disable @typescript-eslint/no-unused-vars */

// Libraries
import {
  Alert,
  Card,
  CardActions,
  CardHeader,
  Avatar,
  Button,
  Tooltip,
  Chip,
  Link,
  CardActionArea,
} from '@mui/material';

import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import DraftsIcon from '@mui/icons-material/Drafts';

// FIles
import { TechnicianCardInterface } from './interface';
import { formatPhoneNumber } from '../../utils/functions/phone/format-phone-number';
import { useNavigate } from 'react-router-dom';

// Scss
import './TechnicianCard.scss';

export default function TechnicianCard({
  data,
  severity = 'info',
}: TechnicianCardInterface) {
  const navigate = useNavigate();
  const { id, name, phone, image, is_new: isNew } = data;
  const handleClick = () => {
    navigate(`/technicians/${data.id}/details`);
  };

  return (
    <Card elevation={1} className='technician-card' onClick={handleClick}>
      <CardActionArea>
        <CardHeader
          className='technician-card__header'
          action={
            !isNew ? (
              <Chip
                label='New'
                variant='outlined'
                color='success'
                size='small'
              />
            ) : null
          }
          avatar={<Avatar src={image?.url} alt={`Picture of ${name}`} />}
          title={name}
          subheader={
            phone ? (
              <Link
                href={`tel:${phone}`}
                color='inherit'
                underline='hover'
                onClick={(e) => e.stopPropagation()}
              >
                {formatPhoneNumber(phone)}
              </Link>
            ) : null
          }
        />

        {/* <CardActions disableSpacing>
        <CardActions>
          <Button size='small' startIcon={<PlayCircleOutlineIcon />}>
            Video call
          </Button>

          <Tooltip
            title='Coming Soon'
            placement='right'
            arrow
            classes={{
              tooltip: 'technician-card__tooltip',
            }}
          >
            <Button size='small' startIcon={<DraftsIcon />}>
              Chat
            </Button>
          </Tooltip>
        </CardActions>
      </CardActions> */}
        {/* <Alert
        className='technician-card__alert'
        icon={false}
        severity={severity}
      >
        3 item(s) need attention
      </Alert> */}
      </CardActionArea>
    </Card>
  );
}
