// Libraries
import { yupResolver } from '@hookform/resolvers/yup';
import { Grid, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { useEffect, useMemo, useState } from 'react';
import { FieldValues, FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../config/redux/hooks';

// Components
import ButtonLink from '../ButtonLink/ButtonLink';
import LoadingButton from '../LoadingButton/LoadingButton';
import LoginFormHeader from '../LoginFormHeader/LoginFormHeader';
import TextFieldInput from '../TextFieldInput/TextFieldInput';
import VisibilityAdornment from '../VisibilityAdornment/VisibilityAdornment';

// Features
import { useLoginMutation } from '../../../features/login/loginSlice';
import { LoginFormData } from '../../../features/login/interface';
import { setLoginAttempt } from '../../../features/auth/authSlice';
import { setCredentials } from '../../../features/persist/persistSlice';

// Files
import { LoginValidationSchema } from './validations/LoginValidationSchema';
import { INVALID_PASSWORD } from '../../constants/validationStrings';
import { AuthLoginAttemptState } from '../../../features/auth/interface';
import { AuthPersistCredentialState } from '../../../features/persist/interface';
import { getLoginErrors } from '../../utils/rtk/rtk';

// scss
import './LoginForm.scss';
import { useLazyGetAccountInfoQuery } from '../../../features/account/accountSlice';

const LoginForm = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [getAccountInfo] = useLazyGetAccountInfoQuery();
  const loginAttempt = useAppSelector((state) => state.auth.loginAttempt);
  const [login, loginResponse] = useLoginMutation();

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const initialValues = {
    email: '',
    password: '',
  };

  const methods = useForm({
    resolver: yupResolver(LoginValidationSchema),
    defaultValues: initialValues,
    mode: 'onBlur',
  });

  const { handleSubmit, setError } = methods;
  const isLoading = useMemo(() => !!loginResponse?.isLoading, [loginResponse]);

  useEffect(() => {
    const { isUninitialized, isError, isSuccess, data, error } = loginResponse;

    if (isUninitialized) {
      return;
    }

    if (isError) {
      const loginErrors = getLoginErrors(error);
      const payload: AuthLoginAttemptState = {
        loginAttempt: loginErrors.errors.attempts_left,
      };

      dispatch(setLoginAttempt(payload));

      const errorOptions = {
        type: 'custom',
        message: loginErrors.errors.password,
      };

      setError('email', errorOptions);
      setError('password', errorOptions);

      return;
    }

    if (isSuccess) {
      const {
        data: { id: userId, token, verified_at: welcomeDisplayedAt },
      } = data;

      const payload: AuthPersistCredentialState = {
        userId,
        token,
        welcomeDisplayedAt,
      };

      dispatch(setCredentials(payload));
      getAccountInfo();
      navigate('/technicians');
    }

    return;
  }, [loginResponse, navigate, dispatch, setError, getAccountInfo]);

  const handleOnSubmit = async ({ email, password }: FieldValues) => {
    const loginFormData: LoginFormData = {
      data: {
        email,
        password,
      },
    };

    login(loginFormData);
  };

  const handleForgotPassword = () => {
    navigate('/forgot-password');
  };

  return (
    <FormProvider {...methods}>
      <form className='login-form' onSubmit={handleSubmit(handleOnSubmit)}>
        <Grid item xs={8}>
          <Box>
            <LoginFormHeader
              header='Log in to Bluon'
              subheader='Please enter your username and password'
            />
            <Typography
              className='login-form-header-warning'
              style={{ display: loginAttempt > 2 ? 'none' : '' }}
            >
              For security resaons, after{' '}
              <span style={{ fontWeight: 700 }}>{`${loginAttempt} more`}</span>{' '}
              login attempts you will have to wait 10 minutes before trying
              again.
            </Typography>
            <TextFieldInput
              InputLabelProps={{
                shrink: true,
              }}
              fullWidth
              className='login-form-email-field'
              variant='outlined'
              label='Email Address'
              name='email'
            />
            <TextFieldInput
              InputLabelProps={{
                shrink: true,
              }}
              fullWidth
              variant='outlined'
              label='Password'
              name='password'
              type={showPassword ? 'text' : 'password'}
              helperText={INVALID_PASSWORD}
              adornment={
                <VisibilityAdornment
                  showText={showPassword}
                  setShowText={setShowPassword}
                />
              }
            />
          </Box>

          <Box>
            <LoadingButton
              fullWidth
              className='login-form-login-button'
              type='submit'
              variant='contained'
              loading={isLoading}
            >
              Sign In
            </LoadingButton>
          </Box>
          <Box className='login-form-forgot-password'>
            <ButtonLink onClick={handleForgotPassword}>
              <Typography variant='body1'>
                Forgot Password? Click Here
              </Typography>
            </ButtonLink>
          </Box>
        </Grid>
      </form>
    </FormProvider>
  );
};

export default LoginForm;
