// Libraries
import { useState } from 'react';
import { Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

// Components
import StyledDialog from '../StyledDialog/StyledDialog';
import StoreUpdateForm from '../StoreUpdateForm/StoreUpdateForm';
import StoreAddForm from '../StoreAddForm/StoreAddForm';

// Features

import { AccountSupplier } from '../../../features/accountSupplier/interface';

const StoreAddDialog = () => {
  const [dialogCloseFunction, setDialogCloseFunction] = useState(
    () => () => {},
  );
  const [open, setOpen] = useState(false);
  const [selectedStore, setSelectedStore] = useState({} as AccountSupplier);
  const [showStoreInfo, setShowStoreInfo] = useState(false);

  // Handle Dialog Open/Close Logic
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setSelectedStore({} as AccountSupplier);
  };

  const handleStoreAdded = () => {
    setShowStoreInfo(true);
  };

  const handleTransitionExited = () => {
    setShowStoreInfo(false);
  };

  return (
    <>
      <Button
        startIcon={<AddIcon />}
        variant='contained'
        color='primary'
        onClick={handleOpen}
      >
        Add Store
      </Button>

      <StyledDialog
        open={open && !showStoreInfo}
        onClose={handleClose}
        scroll='paper'
      >
        <StoreAddForm
          selectedStore={selectedStore}
          onClose={handleClose}
          onStoreSelect={setSelectedStore}
          onStoreAdded={handleStoreAdded}
        />
      </StyledDialog>

      <StyledDialog
        open={open && showStoreInfo}
        onClose={dialogCloseFunction}
        TransitionProps={{
          onExited: handleTransitionExited,
        }}
      >
        <StoreUpdateForm
          id={selectedStore.id}
          name={selectedStore.name}
          title={
            <>
              Add Info for {selectedStore?.name} <span>(Optional)</span>
            </>
          }
          text='Important Note: We do not use this account number for anything. It
                  stays 100% confidential to your account and the techs you have added
                  to your tech network to use and make ordering parts easier.'
          hideDeleteButton
          confirmButtonLabel='Add Store'
          cancelButtonLabel='Skip'
          companySupplier={selectedStore.company_supplier}
          onClose={handleClose}
          setDialogCloseFunction={setDialogCloseFunction}
        />
      </StyledDialog>
    </>
  );
};

export default StoreAddDialog;
