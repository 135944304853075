import { axiosApi } from '../../config/rtk/axiosApi/axiosApi';
import { LoginFormData, LoginResponse } from './interface';

export const loginApi = axiosApi.injectEndpoints({
  endpoints: (build) => ({
    login: build.mutation<LoginResponse, LoginFormData>({
      query: ({ data }: LoginFormData) => {
        return {
          url: '/auth/login',
          method: 'POST',
          data,
        };
      },
    }),
  }),
});

export const { useLoginMutation } = loginApi;
