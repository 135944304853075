// Libraries
import { Button, CircularProgress, Stack } from '@mui/material';
import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

// Components
import FileUpload from '../../../common/components/FileUpload/FileUpload';

// Files
import { AccountLogoFormProps } from './interface';

// Scss
import './AccountLogoForm.scss';
import { AxiosProgressEvent } from 'axios';
import { uploadAccountLogoFormData } from '../../../features/account/interface';
import {
  useUploadAccountLogoMutation,
  useUpdateAccountLogoMutation,
} from '../../../features/account/accountSlice';

const AccountLogoForm = ({ data, onSkip, onSuccess }: AccountLogoFormProps) => {
  const [fileUploadProgress, setFileUploadProgress] = useState(0);
  const [updateLogo, updateLogoResponse] = useUpdateAccountLogoMutation();
  const [uploadLogo, uploadLogoResponse] = useUploadAccountLogoMutation();

  const methods = useForm({
    defaultValues: {
      logo: data.logo,
    },
  });
  const {
    handleSubmit,
    watch,
    getFieldState,
    setError,
    clearErrors,
    formState,
  } = methods;

  const watchLogo = watch('logo');
  const isDisabled =
    updateLogoResponse.isLoading || uploadLogoResponse.isLoading;

  const handleUpload = async () => {
    clearErrors();
    const onUploadProgress = (progressEvent: AxiosProgressEvent) => {
      const { loaded, total } = progressEvent;

      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const progress = Math.round((100 * loaded) / total!);

      setFileUploadProgress(progress);
    };
    const updateAccountInfoFormData: uploadAccountLogoFormData = {
      data: {
        logo: watchLogo,
      },
      onUploadProgress,
    };

    const result = await uploadLogo(updateAccountInfoFormData);

    // Handle any errors on upload
    if ('error' in result) {
      const message =
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (result.error as any).data.message || 'Something went wrong...';

      setError('logo', { type: 'custom', message });
    }
  };

  const handleUpdate = async () => {
    const { data } = uploadLogoResponse;

    if (!data) return onSuccess();

    const result = await updateLogo({
      data: {
        id: data.data.id,
      },
    });

    if ('data' in result && result?.data) {
      onSuccess();
    }
  };

  useEffect(() => {
    const { isDirty } = getFieldState('logo');
    if (isDirty) handleUpload();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchLogo]);

  return (
    <FormProvider {...methods}>
      <form className='account-logo-form' onSubmit={handleSubmit(handleUpload)}>
        <FileUpload
          name='logo'
          progress={fileUploadProgress}
          isUploading={uploadLogoResponse.isLoading}
        />

        <Stack
          className='account-logo-form__actions'
          direction='row'
          spacing={2}
        >
          <Button
            disabled={isDisabled || formState.isDirty}
            size='large'
            variant='outlined'
            onClick={onSkip}
          >
            I ll&apos;do it later
          </Button>

          <Button
            disabled={isDisabled}
            size='large'
            variant='contained'
            onClick={handleUpdate}
          >
            {updateLogoResponse.isLoading ? (
              <Stack direction='row' alignItems='center' spacing={1}>
                <CircularProgress size={18} color='inherit' />
                <span>Loading</span>
              </Stack>
            ) : (
              'Ready To Go'
            )}
          </Button>
        </Stack>
      </form>
    </FormProvider>
  );
};

export default AccountLogoForm;
