import React, { useState } from 'react';
import Checkbox from '../Checkbox/Checkbox';
import {
  Box,
  FormControlLabel,
  FormHelperText,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import TermsOfServiceModal from '../TermsOfServiceModal/TermsOfServiceModal';

import './TermsOfServiceCheckbox.scss';
import { TermsOfServiceCheckboxProps } from './interface';
import { useFormContext } from 'react-hook-form';

const TermsOfServiceCheckbox = ({
  checkboxName,
}: TermsOfServiceCheckboxProps) => {
  // eslint-disable-next-line
  const toolTipTitle = `Please click on the “Terms of Service and Privacy Policies” link to read. You must get to the bottom of the document, then you can ‘check the box’ to continue`;
  const [showToolTip, setShowToolTip] = useState(true);
  const [isServiceRead, setIsServiceRead] = useState(false);
  const { formState, getFieldState } = useFormContext();
  const { error } = getFieldState(checkboxName, formState);

  const handleTOSOpen = () => {
    setShowToolTip(false);
  };

  const handleTOSClose = () => {
    setShowToolTip(true);
  };

  return (
    <Tooltip
      title={
        showToolTip ? (
          <Typography variant='caption' textAlign='center'>
            {toolTipTitle}
          </Typography>
        ) : (
          ''
        )
      }
      placement='top-start'
      arrow
    >
      <Box className='terms-of-service-checkbox'>
        <FormControlLabel
          control={<Checkbox name={checkboxName} disabled={!isServiceRead} />}
          label={
            <Stack direction='row' className='terms-of-service-text'>
              <Typography>I agree with the</Typography>
              <TermsOfServiceModal
                setIsServiceRead={setIsServiceRead}
                handleTOSOpen={handleTOSOpen}
                handleTOSClose={handleTOSClose}
              />
            </Stack>
          }
        />
        <FormHelperText error={!!error}>{error?.message}</FormHelperText>
      </Box>
    </Tooltip>
  );
};

export default TermsOfServiceCheckbox;
