import { axiosApi } from '../../config/rtk/axiosApi/axiosApi';
import {
  TechnicianDetails,
  getTechnicianListRequest,
  getTechnicianListResponse,
} from './interface';

export const technicianApi = axiosApi.injectEndpoints({
  endpoints: (build) => ({
    getTechnicianList: build.query<
      getTechnicianListResponse,
      getTechnicianListRequest
    >({
      query: (params: getTechnicianListRequest) => {
        return {
          url: '/technicians',
          method: 'GET',
          params,
        };
      },
      providesTags: (result) =>
        result
          ? [
              ...result.data.map(({ id }) => ({
                type: 'Technician' as const,
                id,
              })),
              { type: 'Technician', id: 'PARTIAL-LIST' },
            ]
          : [{ type: 'Technician', id: 'PARTIAL-LIST' }],
    }),
    getTechnicianDetails: build.query<TechnicianDetails, string>({
      query: (id) => ({
        url: `/technicians/${id}`,
        method: 'GET',
      }),
      transformResponse: (response: { data: TechnicianDetails }) =>
        response.data,
      providesTags: (result, error, id) => [{ type: 'Technician', id }],
    }),
  }),
});

export const { useGetTechnicianListQuery, useGetTechnicianDetailsQuery } =
  technicianApi;
