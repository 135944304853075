// Libraries
import { Button, Stack, Grid, CircularProgress } from '@mui/material';
import { useForm, useFieldArray, FormProvider } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

// Components
import ConfirmTechFormChip from './ConfirmTechFormChip/ConfirmTechFormChip';

// Files
import { useAppDispatch } from '../../../config/redux/hooks';
import { ConfirmTechFormProps, ConfirmTechFormData } from './interface';
import {
  useUpdateAccountTechniciansMutation,
  useAccountCompleteWelcomeMutation,
} from '../../../features/account/accountSlice';
import { setWelcomeDisplayedAt } from '../../../features/persist/persistSlice';

// Scss
import './ConfirmTechForm.scss';

const ConfirmTechForm = ({ data }: ConfirmTechFormProps) => {
  const dispatch = useAppDispatch();
  const methods = useForm<ConfirmTechFormData>({
    defaultValues: {
      technicians: data,
    },
  });
  const navigate = useNavigate();
  const { control, handleSubmit } = methods;
  const { fields: techniciansFields, update } = useFieldArray({
    control,
    keyName: 'fieldId',
    name: 'technicians',
  });

  const handleBulkUpdate = (confirmVal: boolean = false): void => {
    for (let index = 0; index < techniciansFields.length; index++) {
      update(index, {
        ...techniciansFields?.[index],
        confirmed: confirmVal,
      });
    }
  };

  const [updateTechnicians, updateTechniciansResponse] =
    useUpdateAccountTechniciansMutation();
  const [completeWelcome, completeWelcomeResponse] =
    useAccountCompleteWelcomeMutation();

  const handleOnSubmit = async (data: ConfirmTechFormData) => {
    const updateTechniciansResult = await updateTechnicians({
      data: { ...data },
    });
    const isSuccess =
      'data' in updateTechniciansResult && updateTechniciansResult.data;

    if (isSuccess) {
      const completeWelcomeResponse = await completeWelcome();

      if ('data' in completeWelcomeResponse) {
        dispatch(setWelcomeDisplayedAt(true));
        navigate('/technicians');
      }
    }
  };

  const isLoading =
    updateTechniciansResponse.isLoading || completeWelcomeResponse.isLoading;

  return (
    <FormProvider {...methods}>
      <form
        className='confirm-tech-form'
        onSubmit={handleSubmit(handleOnSubmit)}
      >
        <Stack
          className='confirm-tech-form__actions'
          direction='row'
          spacing={2}
        >
          <Button
            variant='outlined'
            color='primary'
            onClick={() => handleBulkUpdate(true)}
          >
            Select All
          </Button>
          <Button
            variant='outlined'
            color='primary'
            onClick={() => handleBulkUpdate()}
          >
            UnSelect All
          </Button>
        </Stack>

        <Grid
          container
          rowSpacing={3}
          columnSpacing={2}
          className='confirm-tech-form__grid'
        >
          {techniciansFields.map((field, index) => (
            <Grid item key={field.fieldId}>
              <ConfirmTechFormChip index={index} />
            </Grid>
          ))}
        </Grid>

        <Stack
          className='confirm-tech-form__actions'
          direction='row'
          spacing={2}
        >
          <Button
            variant='contained'
            color='primary'
            size='large'
            type='submit'
            disabled={isLoading || !updateTechniciansResponse.isUninitialized}
          >
            {isLoading || !updateTechniciansResponse.isUninitialized ? (
              <Stack direction='row' alignItems='center' spacing={1}>
                <CircularProgress size={18} color='inherit' />
                <span>Loading</span>
              </Stack>
            ) : (
              'Confirm My Techs'
            )}
          </Button>
        </Stack>
      </form>
    </FormProvider>
  );
};

export default ConfirmTechForm;
