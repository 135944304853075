// Libraries
import { useCallback, useEffect, useRef, useState } from 'react';
import useInfiniteScroll from 'react-infinite-scroll-hook';
import {
  Box,
  Button,
  DialogTitle,
  DialogContent,
  DialogActions,
  CircularProgress,
} from '@mui/material';
import { FieldValues } from 'react-hook-form';

// Components
import StoreFilterForm from '../StoreFilterForm/StoreFilterForm';
import StoreSearchList from '../StoreSearchList/StoreSearchList';

// Files

import {
  getStoreAddSuccessToastProps,
  getStoreAddErrorToastProps,
} from '../../utils/toast/store-toast';
import useInfiniteScrollQuery from '../../hooks/useInfiniteScrollQuery/useInfiniteScrollQuery';
import useDebounce from '../../hooks/useDebounce/useDebounce';

import { useGetSupplierListQuery } from '../../../features/supplier/supplierSlice';
import { StoreAddFormProps } from './interface';
import { STORE_SEARCH_PARAMS } from '../../constants/constants';
import { AccountSupplier } from '../../../features/accountSupplier/interface';
import { useAddAccountSupplierMutation } from '../../../features/accountSupplier/accountSupplierSlice';
import useExtendedSnackbar from '../../hooks/useExtendedSnackbar/useExtendedSnackbar';

// Scss
import './StoreAddForm.scss';

const StoreAddForm = ({
  selectedStore,
  onStoreSelect,
  onStoreAdded,
  onClose,
}: StoreAddFormProps) => {
  const dialogContentRef = useRef<null | HTMLDivElement>(null);
  const [filters, setFilters] = useState<FieldValues>(STORE_SEARCH_PARAMS);
  const debouncedFilters = useDebounce(filters);
  const [addAccountSupplier] = useAddAccountSupplierMutation();
  const [showToast] = useExtendedSnackbar();

  const {
    combinedData,
    loadMore,
    refresh,
    hasNextPage,
    isLoading,
    isFetching,
  } = useInfiniteScrollQuery(useGetSupplierListQuery, debouncedFilters);

  const [sentryRef, { rootRef }] = useInfiniteScroll({
    hasNextPage,
    loading: isLoading,
    onLoadMore: loadMore,
    disabled: !!isLoading,
    rootMargin: '0px 0px 200px 0px',
  });

  const handleOnSubmit = () => {
    if (!selectedStore.id) return;

    addAccountSupplier(selectedStore.id)
      .unwrap()
      .then((res) => {
        const storeUpdateSuccessToastProps = getStoreAddSuccessToastProps(
          res.name,
        );
        showToast(storeUpdateSuccessToastProps);
        onStoreAdded();
      })
      .catch(() => {
        const storeUpdateErrorToastProps = getStoreAddErrorToastProps(
          selectedStore.name,
        );
        showToast(storeUpdateErrorToastProps);
      })
      .finally(() => {
        // reset();
        // handleClose();
      });
  };

  const handleRefresh = () => {
    refresh();
    onStoreSelect({} as AccountSupplier);
    if (dialogContentRef?.current) {
      dialogContentRef.current.scrollTop = 0;
    }
  };

  const handleRootRef = useCallback((e: HTMLDivElement) => {
    rootRef(e);
    dialogContentRef.current = e;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    handleRefresh();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedFilters]);

  return (
    <>
      <DialogTitle component='div'>
        <StoreFilterForm
          defaultValues={STORE_SEARCH_PARAMS}
          onChange={setFilters}
        />
      </DialogTitle>

      <DialogContent ref={handleRootRef} className='store-add-form-content'>
        {isLoading ? (
          <Box className='store-add-form-content__spinner'>
            <CircularProgress size={25} />
          </Box>
        ) : (
          <>
            <StoreSearchList
              activeStore={selectedStore}
              data={combinedData}
              searchValue={debouncedFilters.search_string}
              onSearchCardClick={onStoreSelect}
            />
            {(isFetching || hasNextPage) && (
              <Box ref={sentryRef} className='store-add-form-content__spinner'>
                <CircularProgress size={20} />
              </Box>
            )}
          </>
        )}
      </DialogContent>

      <DialogActions>
        <Button variant='outlined' onClick={onClose}>
          Cancel
        </Button>

        <Button
          variant='contained'
          disabled={!selectedStore?.id}
          onClick={handleOnSubmit}
        >
          Continue
        </Button>
      </DialogActions>
    </>
  );
};

export default StoreAddForm;
