// Libraries
import { Chip } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import AddIcon from '@mui/icons-material/Add';
import {
  Controller,
  useFormContext,
  useWatch,
  useFieldArray,
} from 'react-hook-form';

// Files
import { ConfirmTechFormChipProps } from './interface';

const ConfirmTechFormChip = ({ index }: ConfirmTechFormChipProps) => {
  const { control } = useFormContext();
  const value = useWatch({
    name: 'technicians',
    control,
  });

  const { update } = useFieldArray({
    control,
    name: 'technicians',
  });

  const handleClick = () => {
    update(index, {
      ...value?.[index],
      confirmed: !value?.[index]?.confirmed,
    });
  };

  return (
    <Controller
      control={control}
      name={`technicians.${index}.confirmed` as const}
      render={() => (
        <>
          <Chip
            icon={value?.[index]?.confirmed ? <CheckCircleIcon /> : <AddIcon />}
            label={value?.[index]?.name}
            color='primary'
            variant={value?.[index]?.confirmed ? 'filled' : 'outlined'}
            onClick={handleClick}
          />
        </>
      )}
    />
  );
};

export default ConfirmTechFormChip;
