// Libraries
import Grid from '@mui/material/Grid';

// Components
import { TechniciansListProps } from './interface';
import { Technician } from '../../../features/technician/interface';
import TechnicianCard from './../../components/TechnicianCard/TechnicianCard';

const TechniciansList = ({ data }: TechniciansListProps) => (
  <Grid container spacing={2.5} py={[3]}>
    {data.map((item: Technician) => (
      <Grid item xs={12} sm={6} md={4} lg={3} key={item.id}>
        <TechnicianCard data={item} />
      </Grid>
    ))}
  </Grid>
);

export default TechniciansList;
