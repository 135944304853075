// Libraries
import { Grid, Typography } from '@mui/material';

// Components
import StoreAddDialog from '../../../common/components/StoreAddDialog/StoreAddDialog';

const StoresTopBar = () => {
  return (
    <Grid className='stores__top-bar' container>
      <Grid item>
        <Grid container>
          <Grid item>
            <Typography variant='h5'>Your Stores</Typography>
          </Grid>
        </Grid>
      </Grid>

      <Grid item>
        <StoreAddDialog />
      </Grid>
    </Grid>
  );
};

export default StoresTopBar;
