// Libraries
import { Box } from '@mui/material';

// Components
import OnboardingHighlightHeader from '../OnboardingHighlightHeader/OnboardingHighlightHeader';

// Files
import { OnboardingImageHighlightProps } from './interface';

// Scss
import './OnboardingImageHighlight.scss';

// * Think of a way to dynamically draw the connection line
const OnboardingImageHighlight = ({
  src,
  title,
  subTitle,
}: OnboardingImageHighlightProps) => {
  return (
    <Box className='onboarding-image-highlight'>
      <div className='onboarding-image-highlight__top'>
        <div className='onboarding-image-highlight__top-left'>
          <OnboardingHighlightHeader title={title} subTitle={subTitle} />
        </div>

        <div className='onboarding-image-highlight__top-right'>
          <div className='onboarding-image-highlight__top-spacer' />
        </div>
      </div>

      <Box className='onboarding-image-highlight__media'>
        <img src={src} alt='' />
      </Box>
    </Box>
  );
};

export default OnboardingImageHighlight;
