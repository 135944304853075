// Files
import { StyledAlertProps } from './../../components/StyledAlert/interface';

import {
  BROKEN_URL_MESSAGE,
  PASSWORD_ERROR_MESSAGE,
  ERROR_TITLE,
  PASSWORD_SUCCESS_MESSAGE,
  PASSWORD_SUCCESS_TITLE,
  EXPIRED_LINK_MESSAGE,
  ACCOUNT_UPDATE_SUCCESS_TITLE,
  ACCOUNT_UPDATE_SUCCESS_MESSAGE,
  ACCOUNT_UPDATE_ERROR_TITLE,
  ACCOUNT_UPDATE_ERROR_MESSAGE,
  TECHNICIAN_CREATE_SUCCESS_TITLE,
  TECHNICIAN_CREATE_SUCCESS_MESSAGE,
  TECHNICIAN_CREATE_ERROR_TITLE,
  TECHNICIAN_CREATE_ERROR_MESSAGE,
  TECHNICIAN_CREATE_SUCCESS_WITH_USER_MESSAGE,
} from '../../constants/toastConfig';

export const getPasswordSuccessToastProps = (): StyledAlertProps => {
  return {
    title: PASSWORD_SUCCESS_TITLE,
    message: PASSWORD_SUCCESS_MESSAGE,
    severity: 'success',
    persist: false,
  };
};

export const getPasswordErrorToastProps = (): StyledAlertProps => {
  return {
    title: ERROR_TITLE,
    message: PASSWORD_ERROR_MESSAGE,
    severity: 'error',
    persist: false,
  };
};

export const getBrokenURLErrorToastProps = (): StyledAlertProps => {
  return {
    title: ERROR_TITLE,
    message: BROKEN_URL_MESSAGE,
    severity: 'error',
    persist: false,
  };
};

export const getExpiredErrorToastProps = (): StyledAlertProps => {
  return {
    title: ERROR_TITLE,
    message: EXPIRED_LINK_MESSAGE,
    severity: 'error',
    persist: false,
  };
};

// ACCOUNT

export const getAccountUpdateSuccessToastProps = (): StyledAlertProps => {
  return {
    title: ACCOUNT_UPDATE_SUCCESS_TITLE,
    message: ACCOUNT_UPDATE_SUCCESS_MESSAGE,
    severity: 'success',
    persist: false,
  };
};

export const getAccountUpdateErrorToastProps = (): StyledAlertProps => {
  return {
    title: ACCOUNT_UPDATE_ERROR_TITLE,
    message: ACCOUNT_UPDATE_ERROR_MESSAGE,
    severity: 'error',
    persist: false,
  };
};

// Technician Invitation Toasts
export const addTechnicianSuccessToastProps = (): StyledAlertProps => {
  return {
    title: TECHNICIAN_CREATE_SUCCESS_TITLE,
    message: TECHNICIAN_CREATE_SUCCESS_MESSAGE,
    severity: 'success',
    persist: false,
  };
};

export const addTechnicianSuccessWithUserToastProps = (
  fullName: string,
): StyledAlertProps => {
  return {
    title: TECHNICIAN_CREATE_SUCCESS_TITLE,
    message: `${TECHNICIAN_CREATE_SUCCESS_WITH_USER_MESSAGE} ${fullName}`,
    severity: 'success',
    persist: false,
  };
};

export const addTechnicianErrorToastProps = (): StyledAlertProps => {
  return {
    title: TECHNICIAN_CREATE_ERROR_TITLE,
    message: TECHNICIAN_CREATE_ERROR_MESSAGE,
    severity: 'error',
    persist: false,
  };
};
