// Libraries
import { useNavigate } from 'react-router-dom';

// Files
import { useAppDispatch } from '../../../config/redux/hooks';
import { setCredentials } from '../../../features/persist/persistSlice';
import { AuthPersistCredentialState } from '../../../features/persist/interface';
import {
  getBrokenURLErrorToastProps,
  getPasswordSuccessToastProps,
} from '../../utils/toast/toast';
import { useCreatePasswordMutation } from '../../../features/password/passwordSlice';
import { CreatePasswordFormData } from '../../../features/password/interface';
import useExtendedSnackbar from '../useExtendedSnackbar/useExtendedSnackbar';

/* eslint-disable @typescript-eslint/no-explicit-any */
export const useCreatePassword = (): [
  (formData: CreatePasswordFormData) => void,
  any,
] => {
  const [createPassword, createPasswordResponse] = useCreatePasswordMutation();
  const [showToast] = useExtendedSnackbar();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const createPasswordWithToasts = async (formData: CreatePasswordFormData) => {
    await createPassword(formData)
      .unwrap()
      .then(({ id, token, verified_at }) => {
        const passwordSuccessToastProps = getPasswordSuccessToastProps();
        showToast(passwordSuccessToastProps);

        const authPersistPayload: AuthPersistCredentialState = {
          userId: id,
          token: token,
          welcomeDisplayedAt: verified_at,
        };

        dispatch(setCredentials(authPersistPayload));
        navigate('/technicians');
      })
      .catch(() => {
        const passwordErrorToastProps = getBrokenURLErrorToastProps();
        showToast(passwordErrorToastProps);
      });
  };

  return [createPasswordWithToasts, createPasswordResponse];
};
