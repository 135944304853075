// Libraries

import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

// Components
import App from './app/App';
import ExtendedSnackbarProvider from './common/components/ExtendedSnackbarProvider/ExtendedSnackbarProvider';

// Files
import { persistor, store } from './config/redux/store';

// scss
import './index.scss';

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const container = document.getElementById('root')!;
const root = createRoot(container);

root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <ExtendedSnackbarProvider>
        <App />
      </ExtendedSnackbarProvider>
    </PersistGate>
  </Provider>,
);
