// Components
import LoginForm from '../../common/components/LoginForm/LoginForm';
import LoginLocked from '../../common/components/LoginLocked/LoginLocked';
import { useAppSelector } from '../../config/redux/hooks';

// scss
import './Login.scss';

const Login = () => {
  const loginAttempt = useAppSelector((state) => state.auth.loginAttempt);
  const isLocked = loginAttempt <= 0 ? true : false;

  return !isLocked ? <LoginForm /> : <LoginLocked />;
};

export default Login;
