// Libraries
import { Box, Paper } from '@mui/material';

// Files
import { HoverDropdownProps } from './interface';

// scss
import './HoverDropdown.scss';

const HoverDropdown = ({ src, children }: HoverDropdownProps) => {
  return (
    <Box className='hoverdropdown'>
      <Box className='hoverdropdown-icon-container'>
        <Box className='hoverdropdown-icon'>
          <img src={src} />
        </Box>
      </Box>
      <Paper className='hoverdropdown-list' elevation={1}>
        {children}
      </Paper>
    </Box>
  );
};

export default HoverDropdown;
