// Libraries
import Box from '@mui/material/Box';
import EditIcon from '@mui/icons-material/Edit';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';

// Components
import GoogleMap from '../GoogleMap/GoogleMap';
import StoreDetailsRepCard from '../StoreDetailsRepCard/StoreDetailsRepCard';
import StoreDetailsAccountNumber from '../StoreDetailsAccountNumber/StoreDetailsAccountNumber';

// Files
import { StoreDetailsProps } from './interface';
import { formatPhoneNumber } from '../../utils/functions/phone/format-phone-number';
import {
  StateHandlerError,
  StateHandlerLoading,
} from '../StateHandler/StateHandler';
import { useGetAccountSupplierDetailsQuery } from '../../../features/accountSupplier/accountSupplierSlice';

// Scss
import './StoreDetails.scss';

const StoreDetails = ({ id, onEditClick }: StoreDetailsProps) => {
  const { data, isLoading } = useGetAccountSupplierDetailsQuery(id);

  if (isLoading) return <StateHandlerLoading />;
  if (!data) return <StateHandlerError />;

  const {
    name,
    phone,
    email,
    image,
    address,
    latitude,
    longitude,
    company_supplier: {
      account_number,
      sales_rep_email,
      sales_rep_phone,
      sales_rep_name,
    },
  } = data;

  const showMap = latitude && longitude;

  return (
    <Box>
      <Card raised={false} elevation={0} className='store-details'>
        <CardMedia
          component='img'
          height='300'
          image={image?.url || 'http://via.placeholder.com/430x300'}
          alt='Card Placeholder'
        />

        <CardHeader
          className='store-details__header'
          action={
            <IconButton
              className='store-details__edit-btn'
              aria-label='Edit Store'
              size='medium'
              color='primary'
              onClick={onEditClick}
            >
              <EditIcon fontSize='inherit' />
            </IconButton>
          }
          title={name}
        />

        <CardContent className='store-details__content'>
          {address && (
            <Typography
              className='store-details__content-address'
              component='address'
              variant='body2'
            >
              {address}
            </Typography>
          )}

          {(phone || email) && (
            <Typography
              className='store-details__content-meta'
              gutterBottom={true}
              variant='body2'
            >
              {phone && (
                <>
                  {'Phone #: '}
                  {formatPhoneNumber(phone)}
                </>
              )}
              {email && (
                <>
                  {' · '}
                  {'Email: '} {email}
                </>
              )}
            </Typography>
          )}

          <StoreDetailsAccountNumber accountNumber={account_number} />

          <StoreDetailsRepCard
            title='Sales Representative'
            name={sales_rep_name}
            email={sales_rep_email}
            phone={sales_rep_phone}
          />
        </CardContent>
      </Card>

      {showMap && <GoogleMap lat={+latitude} lng={+longitude} />}
    </Box>
  );
};

export default StoreDetails;
