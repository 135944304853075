import { Box, Typography } from '@mui/material';
import { NavLink } from 'react-router-dom';
import { NavBarTabProps } from './interface';

// scss
import './NavBarTab.scss';

const NavBarTab = ({ src, label, to, style }: NavBarTabProps) => {
  return (
    <NavLink
      to={to}
      className={({ isActive }) => {
        const isActiveClassName = isActive ? 'active' : 'inactive';

        return `navbar-tab-navlink ${isActiveClassName}`;
      }}
      style={style}
    >
      <Box className='navbar-tab'>
        <img src={src} alt={`${label} tab`} />
        <Typography>{label}</Typography>
      </Box>
    </NavLink>
  );
};

export default NavBarTab;
