import { Service } from '../../../interfaces/models';
import { UpdateCompanyFormValues } from '../interface';
import {
  Company,
  UpdateCompanyFormData,
} from './../../../../features/company/interface';

export const normalizeUpdateCompanyFormInputData = (
  company: Company,
  services: Service[],
): UpdateCompanyFormValues => {
  const companyServicesIds = company.services?.map((i) => i.id);
  const normalizedServices = services.map((service) => ({
    service_id: service.id,
    service_name: service.name,
    default_checked: companyServicesIds?.includes(service.id),
  }));

  return {
    ...company,
    company_services: normalizedServices,
  };
};

export const normalizeUpdateCompanyFormOutputData = (
  values: UpdateCompanyFormValues,
): UpdateCompanyFormData => {
  const updateCompanyValues = { ...values } as UpdateCompanyFormValues;

  // Parse Company Services to the proper format
  const parsedCompanyValues = values.company_services
    .filter((service) => service.default_checked === true)
    .map((service) => service.service_id);

  // Remove logo when we don't upload new logo
  if (!(values.logo instanceof File)) {
    delete updateCompanyValues.logo;
  }

  return {
    ...updateCompanyValues,
    number_of_employees: values.number_of_techs,
    company_services: parsedCompanyValues,
  };
};
