import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AuthPersistCredentialState, PersistState } from './interface';

export const initialState: PersistState = {
  userId: '',
  token: '',
  welcomeDisplayedAt: null,
};

const persistSlice = createSlice({
  name: 'persist',
  initialState,
  reducers: {
    setCredentials: (
      state,
      {
        payload: { userId, token, welcomeDisplayedAt },
      }: PayloadAction<AuthPersistCredentialState>,
    ) => {
      state.userId = userId;
      state.token = token;
      state.welcomeDisplayedAt = welcomeDisplayedAt;
    },
    setWelcomeDisplayedAt: (
      state,
      {
        payload,
      }: PayloadAction<AuthPersistCredentialState['welcomeDisplayedAt']>,
    ) => {
      state.welcomeDisplayedAt = payload;
    },
  },
});

export const { setCredentials, setWelcomeDisplayedAt } = persistSlice.actions;

export default persistSlice.reducer;
