// Libraries
import { Outlet, Navigate } from 'react-router-dom';
import { Grid } from '@mui/material';
import { useJwt } from 'react-jwt';

// Components
import LoginLayout from '../../common/components/LoginLayout/LoginLayout';
import TermsOfService from '../../common/components/TermsOfService/TermsOfService';
import LoginLoaderProvider from '../../common/components/LoginLoaderProvider/LoginLoaderProvider';

// Files
import { useAppSelector } from '../../config/redux/hooks';

// scss
import './LoginRoutes.scss';

const LoginRoutes = () => {
  const token = useAppSelector((state) => state.persist.token);

  /**
   * ? In the future we may want to implement a way to validate the JWT token using the backend API, instead of only using FE validation
   */
  const { isExpired } = useJwt(token);

  if (token && !isExpired) {
    return <Navigate to='/technicians' />;
  }

  return (
    <Grid container className='login-route'>
      <Grid item xs={5}>
        <LoginLayout />
      </Grid>

      <Grid
        container
        className='login-route-page-container'
        direction='column'
        item
        xs={7}
      >
        <LoginLoaderProvider>
          <Grid item className='login-route-page'>
            <Outlet />
          </Grid>

          <Grid
            item
            className='login-route-terms-of-service specific-terms-of-service'
          >
            <TermsOfService />
          </Grid>
        </LoginLoaderProvider>
      </Grid>
    </Grid>
  );
};

export default LoginRoutes;
