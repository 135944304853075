export const env = process.env;

export const MAX_FILE_SIZE_3MB = 3145728;

export const RADIUS_OPTIONS = [
  {
    value: '10',
    label: '10 mi',
  },
  {
    value: '25',
    label: '25 mi',
  },
  {
    value: '50',
    label: '50 mi',
  },
  {
    value: '75',
    label: '75 mi',
  },
  {
    value: '100',
    label: '100+ mi',
  },
];

export const STORE_FILTER_OPTIONS = [
  {
    value: '',
    label: 'All',
  },
  {
    value: 'has-sales-rep',
    label: 'Has Sales Rep',
  },
  {
    value: 'on-bluon',
    label: 'On Network',
  },
];

export const STORE_TYPE_OPTIONS = [
  {
    value: 'name',
    label: 'name',
  },
  {
    value: 'address',
    label: 'address',
  },
];

export const STORE_SEARCH_PARAMS = {
  type: 'name',
  radius: '25',
  search_string: '',
};

export const TERM_OF_USE_VERSION = '2023';
