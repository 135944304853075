// TODO Bring back orders nav Link page after MVP
/* eslint-disable @typescript-eslint/no-unused-vars */

// Libraries
import {
  Box,
  Grid,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';

// Components
import NavBarTab from '../NavBarTab/NavBarTab';
import HoverDropdown from '../HoverDropdown/HoverDropdown';
import PersonIcon from '@mui/icons-material/Person';
import LogoutIcon from '@mui/icons-material/Logout';

// Files
import techniciansIcon from '../../../assets/icons/technicians-icon.svg';
import storesIcon from '../../../assets/icons/stores-icon.svg';
import ordersIcon from '../../../assets/icons/orders-icon.svg';
import accountIcon from '../../../assets/icons/account-icon.svg';
// import defaultLogo from '../../../assets/images/logo.svg';

// scss
import './NavBar.scss';
import { useGetAccountInfoQuery } from '../../../features/account/accountSlice';
import { useEffect, useState } from 'react';
import { useAppSelector, useAppDispatch } from '../../../config/redux/hooks';
import { AuthPersistCredentialState } from '../../../features/persist/interface';
import { setCredentials } from '../../../features/persist/persistSlice';
import { axiosApi } from '../../../config/rtk/axiosApi/axiosApi';

const NavBar = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const url = useAppSelector((state) => state.logo.url);
  const [logoUrl, setLogoUrl] = useState('');

  const { isSuccess, data } = useGetAccountInfoQuery();
  useEffect(() => {
    if (isSuccess) {
      const url = data.data.logo?.url || '';
      setLogoUrl(url);
    }
  }, [isSuccess, data]);

  useEffect(() => {
    if (!url) {
      return;
    }

    setLogoUrl(url);
  }, [url]);

  const handleAccountOnClick = () => {
    navigate('/account');
  };

  const handleLogoutOnClick = () => {
    // onlogout clear storage
    const logoutCredentials: AuthPersistCredentialState = {
      userId: '',
      token: '',
    };

    dispatch(setCredentials(logoutCredentials));
    dispatch(axiosApi.util.resetApiState());
    navigate('/login');
  };

  return (
    <Grid container className='navbar'>
      <Grid item>
        {/* might need to use redux store to grab actual image when grabbing user information on login */}
        <Box className='navbar-logo-container'>
          <img
            className='navbar-logo'
            src={logoUrl}
            alt='company-logo'
            style={{ visibility: !logoUrl ? 'hidden' : 'visible' }}
          />
        </Box>
      </Grid>
      <Grid container item className='navbar-tabs'>
        <NavBarTab
          to='/technicians'
          src={techniciansIcon}
          label='Technicians'
        />
        <NavBarTab to='/stores' src={storesIcon} label='Stores' />
        {/* <NavBarTab to='/orders' src={ordersIcon} label='Orders' /> */}

        <HoverDropdown src={accountIcon}>
          <List>
            <ListItem disablePadding onClick={handleAccountOnClick}>
              <ListItemButton>
                <ListItemIcon>
                  <PersonIcon />
                </ListItemIcon>
                <ListItemText primary='Account' />
              </ListItemButton>
            </ListItem>

            <ListItem disablePadding onClick={handleLogoutOnClick}>
              <ListItemButton>
                <ListItemIcon>
                  <LogoutIcon />
                </ListItemIcon>
                <ListItemText primary='Logout' />
              </ListItemButton>
            </ListItem>
          </List>
        </HoverDropdown>
      </Grid>
    </Grid>
  );
};

export default NavBar;
