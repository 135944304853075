// Libraries
import cx from 'classnames';
import {
  Button,
  Stack,
  FormControlLabel,
  FormGroup,
  Grid,
  Typography,
  FormControl,
  FormHelperText,
} from '@mui/material';
import { FormProvider, useFieldArray } from 'react-hook-form';
import { useState } from 'react';

// Components
import Dropdown from '../Dropdown/Dropdown';
import Checkbox from '../Checkbox/Checkbox';
import PhoneFieldInput from '../PhoneFieldInput/PhoneFieldInput';
import TextFieldInput from '../TextFieldInput/TextFieldInput';

// Files
import { US_STATES_SHORT } from '../../constants/states';
import { AccountConfirmFormProps } from './interface';
import useUpdateCompanyForm from '../../hooks/useUpdateCompanyForm/useUpdateCompanyForm';

// Scss
import './AccountConfirmForm.scss';

const AccountConfirmForm = ({
  data,
  onSkip = () => {},
  onSuccess = () => {},
}: AccountConfirmFormProps) => {
  const [disabled, setDisabled] = useState(true);
  const [methods, handleSubmit] = useUpdateCompanyForm({
    data,
    onSuccess,
  });

  const { control, formState, getFieldState, reset } = methods;
  const { error: serviceFieldsError } = getFieldState(
    'company_services',
    formState,
  );
  const { fields: serviceFields } = useFieldArray({
    control,
    name: 'company_services',
  });

  // Functions
  const handleDiscard = () => {
    setDisabled(true);
    reset();
  };

  return (
    <FormProvider {...methods}>
      <form
        className={cx('account-confirm-form', { 'is-disabled': disabled })}
        onSubmit={handleSubmit}
      >
        <Grid container columnSpacing={2} rowSpacing={4}>
          <Grid item xs={6}>
            <TextFieldInput
              disabled={disabled}
              fullWidth
              name='name'
              label='Company Name'
              className='account-field-input'
            />
          </Grid>

          <Grid item xs={3}>
            <TextFieldInput
              disabled={disabled}
              fullWidth
              name='years_in_business'
              label='Years in Business'
              className='account-field-input'
            />
          </Grid>
          <Grid item xs={3}>
            <TextFieldInput
              disabled={disabled}
              fullWidth
              name='number_of_employees'
              label='# of techs'
              className='account-field-input'
            />
          </Grid>

          <Grid item xs={6}>
            <PhoneFieldInput
              disabled={disabled}
              fullWidth
              name='phone'
              label='Phone Number'
            />
          </Grid>
          <Grid item xs={6}>
            <TextFieldInput
              disabled={disabled}
              fullWidth
              name='email'
              label='Email Address'
              className='account-field-input'
            />
          </Grid>
          <Grid item xs={6}>
            <TextFieldInput
              disabled={disabled}
              fullWidth
              name='address'
              label='Address 1'
              className='account-field-input'
            />
          </Grid>
          <Grid item xs={6}>
            <TextFieldInput
              disabled={disabled}
              fullWidth
              name='address_2'
              label='Address 2'
              className='account-field-input'
            />
          </Grid>
          <Grid item xs={7}>
            <TextFieldInput
              disabled={disabled}
              fullWidth
              name='city'
              label='City'
              className='account-field-input'
            />
          </Grid>
          <Grid item xs={2.5}>
            <Dropdown
              disabled={disabled}
              fullWidth
              name='state'
              label='State'
              items={US_STATES_SHORT}
              className='account-field-input'
            />
          </Grid>
          <Grid item xs={2.5}>
            <TextFieldInput
              disabled={disabled}
              fullWidth
              name='zip_code'
              label='Zip'
              className='account-field-input'
            />
          </Grid>
          <Grid item xs={12}>
            <FormControl
              className='account-company-services'
              name='services'
              component='fieldset'
              error={!!serviceFieldsError}
            >
              <Typography variant='h6'>Company Services</Typography>

              <FormGroup>
                {serviceFields.map((field, index) => (
                  <FormControlLabel
                    key={field.id}
                    control={
                      <Checkbox
                        disabled={disabled}
                        name={`company_services[${index}].default_checked`}
                      />
                    }
                    label={
                      <Typography variant='body1'>
                        {field.service_name}
                      </Typography>
                    }
                  />
                ))}
              </FormGroup>
              <FormHelperText>{serviceFieldsError?.message}</FormHelperText>
            </FormControl>
          </Grid>
        </Grid>

        <Stack
          className='account-confirm-form__actions'
          direction='row'
          spacing={2}
        >
          {disabled ? (
            <>
              <Button
                size='large'
                variant='outlined'
                onClick={() => {
                  setDisabled(false);
                }}
              >
                Edit
              </Button>

              <Button size='large' variant='contained' onClick={onSkip}>
                YEP, THAT&apos;S ME!
              </Button>
            </>
          ) : (
            <>
              <Button size='large' variant='outlined' onClick={handleDiscard}>
                CANCEL
              </Button>

              <Button size='large' variant='contained' type='submit'>
                Save
              </Button>
            </>
          )}
        </Stack>
      </form>
    </FormProvider>
  );
};

export default AccountConfirmForm;
