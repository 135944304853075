import { Box, Stack, Typography } from '@mui/material';
import TechnicianInvitationCard from '../TechnicianInvitationCard/TechnicianInvitationCard';
import { TechnicianInvitationSidebarProps } from './interface';
import { useGetTechnicianInvitationListQuery } from '../../../features/technician-invitation/technicianInvitationSlice';

// Scss
import './TechnicianInvitationSidebar.scss';

const TechnicianInvitationSidebar = ({
  show = true,
}: TechnicianInvitationSidebarProps) => {
  const { data: response, isLoading } = useGetTechnicianInvitationListQuery({
    status: 'pending',
  });

  const invitations = response?.data;

  if (show == false || isLoading || !invitations?.length) {
    return null;
  }

  return (
    <Box className='technician-invitation-sidebar'>
      <Typography variant='h6'>Waiting for Approval:</Typography>

      <Stack spacing={2}>
        {invitations.map((invitation) => (
          <TechnicianInvitationCard key={invitation.id} data={invitation} />
        ))}
      </Stack>
    </Box>
  );
};

export default TechnicianInvitationSidebar;
