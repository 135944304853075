// Libraries
import { Grid } from '@mui/material';
import { Outlet, Navigate, useLocation } from 'react-router-dom';
import NavBar from '../../common/components/NavBar/NavBar';
import { useAppSelector } from '../../config/redux/hooks';

// Files
import { PrivateRoutesProps } from './interface';
import useServerSnackbar from '../../common/hooks/useServerSnackbar/useServerSnackbar';

// Scss
import './PrivateRoutes.scss';

const PrivateRoutes = ({ keepHeader = true }: PrivateRoutesProps) => {
  const { pathname } = useLocation();
  const token = useAppSelector((state) => state.persist.token);
  const welcomeDisplayedAt = useAppSelector(
    (state) => state.persist.welcomeDisplayedAt,
  );
  const ignoreOnBoarding = true;
  // TODO: Remove ignoreOnBoarding after API fixed
  useServerSnackbar();

  if (!token) {
    return <Navigate to='/login' />;
  }

  if (!welcomeDisplayedAt && pathname !== '/onboarding' && !ignoreOnBoarding) {
    return <Navigate to='/onboarding' />;
  }

  return (
    <Grid container className='private-route'>
      {keepHeader && (
        <Grid item>
          <NavBar />
        </Grid>
      )}

      <Grid item className='private-route-page'>
        <Outlet />
      </Grid>
    </Grid>
  );
};

export default PrivateRoutes;
