// Libraries
import { useState } from 'react';
import { IconButton, Typography } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

// Files
import { StoreDetailsAccountNumberProps } from './interface';

// Scss
import './StoreDetailsAccountNumber.scss';

const StoreDetailsAccountNumber = ({
  visiblePartLength = 4,
  accountNumber,
}: StoreDetailsAccountNumberProps) => {
  const [show, setShow] = useState(false);
  const VisibilityIconComponent = show ? VisibilityOffIcon : VisibilityIcon;
  if (!accountNumber) return null;

  const before = accountNumber.slice(0, visiblePartLength);
  const after = accountNumber.slice(visiblePartLength + 1);

  const toggle = () => {
    setShow(!show);
  };

  return (
    <Typography className='store-details-account-number' variant='body2'>
      {'Account #: '}
      {before}
      {show ? after : after.replace(/./g, '*')}
      <IconButton
        className='store-details-account-number__button'
        onClick={toggle}
      >
        <VisibilityIconComponent className='store-details-account-number__icon' />
      </IconButton>
    </Typography>
  );
};

export default StoreDetailsAccountNumber;
