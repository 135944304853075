// Libraries
import { Box, Button } from '@mui/material';

// Files
import busImg from './../../../../assets/images/bus.png';
import { OnboardingStepComponentProps } from '../../interface';

// Scss
import './OnboardingStepOne.scss';

const OnboardingStepOne = ({ onNextPage }: OnboardingStepComponentProps) => {
  return (
    <Box className='onboarding-step-one'>
      <figure className='onboarding-step-one__image'>
        <img src={busImg} alt='Bluon bus' />
      </figure>

      <Button size='large' variant='contained' onClick={onNextPage}>
        GOT IT, CONTINUE
      </Button>
    </Box>
  );
};

export default OnboardingStepOne;
