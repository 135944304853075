// Components
import StateHandlerLoading from '../../../../common/components/StateHandler/components/StateHandlerLoading/StateHandlerLoading';
import StateHandlerError from '../../../../common/components/StateHandler/components/StateHandlerError/StateHandlerError';

// Files
import AccountLogoForm from '../../../../common/components/AccountLogoForm/AccountLogoForm';
import { useGetAccountInfoQuery } from '../../../../features/account/accountSlice';
import { OnboardingStepComponentProps } from '../../interface';

const OnboardingStepThree = ({ onNextPage }: OnboardingStepComponentProps) => {
  const { data, isLoading } = useGetAccountInfoQuery();

  if (isLoading) return <StateHandlerLoading />;
  if (!data) return <StateHandlerError />;

  return (
    <AccountLogoForm
      data={data.data}
      onSkip={onNextPage}
      onSuccess={onNextPage}
    />
  );
};

export default OnboardingStepThree;
